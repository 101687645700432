import { useTheme } from "next-themes";

export function DarkModeToggle(props) {
    const { setTheme } = useTheme();
    return (
        <div className={props.className}>
            <button
                type="button"
                className="hs-dark-mode-active:hidden group flex dark:hidden items-center text-gray-300 hover:text-gray-400 font-medium dark:text-neutral-400 dark:hover:text-white"
                onClick={() => {
                    setTheme("dark");
                    window.umami?.track("Dark Mode Toggle - Dark mode", {});
                }}
            >
                <svg
                    className="flex-shrink-0 size-4"
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    viewBox="0 0 24 24"
                    fill="none"
                    stroke="currentColor"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                >
                    <path d="M12 3a6 6 0 0 0 9 9 9 9 0 1 1-9-9Z"></path>
                </svg>
            </button>
            <button
                type="button"
                className="hs-dark-mode-active:block hidden group dark:flex items-center text-gray-600 hover:text-black font-medium dark:text-neutral-400 dark:hover:text-white"
                onClick={() => {
                    setTheme("light");
                    window.umami?.track("Dark Mode Toggle - Light mode", {});
                }}
            >
                <svg
                    className="flex-shrink-0 size-4"
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    viewBox="0 0 24 24"
                    fill="none"
                    stroke="currentColor"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                >
                    <circle cx="12" cy="12" r="4"></circle>
                    <path d="M12 2v2"></path>
                    <path d="M12 20v2"></path>
                    <path d="m4.93 4.93 1.41 1.41"></path>
                    <path d="m17.66 17.66 1.41 1.41"></path>
                    <path d="M2 12h2"></path>
                    <path d="M20 12h2"></path>
                    <path d="m6.34 17.66-1.41 1.41"></path>
                    <path d="m19.07 4.93-1.41 1.41"></path>
                </svg>
            </button>
        </div>
    );
}
