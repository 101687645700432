import { Menu } from "@headlessui/react";
import { ArrowRightOnRectangleIcon, StarIcon, UserIcon } from "@heroicons/react/24/outline";
import Link from "next/link";

export function Avatar(props) {
    const { supabase } = props;

    return (
        <>
            <Menu as="div" className="inline-block text-left z-50">
                <Menu.Button>
                    <span
                        className="inline-block h-9 w-9 overflow-hidden rounded-full bg-gray-100 dark:bg-gray-800"
                        style={{ opacity: props.opacity || 1 }}
                    >
                        <svg
                            className="h-full w-full text-gray-300 dark:text-gray-400"
                            fill="currentColor"
                            viewBox="0 0 24 24"
                        >
                            <path d="M24 20.993V24H0v-2.996A14.977 14.977 0 0112.004 15c4.904 0 9.26 2.354 11.996 5.993zM16.002 8.999a4 4 0 11-8 0 4 4 0 018 0z" />
                        </svg>
                    </span>
                </Menu.Button>
                <Menu.Items className="absolute z-50 right-0 z-10 mt-2 w-56 origin-top-right rounded-md bg-white dark:bg-gray-800 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                    <div className="py-1">
                        <form method="POST" action="#">
                            <Menu.Item>
                                {({ active }) => (
                                    <Link href="/account">
                                        <button
                                            className={
                                                "flex text-gray-900 w-full px-4 py-2 text-left text-sm hover:bg-gray-100 dark:hover:bg-gray-700 dark:text-white items-center"
                                            }
                                        >
                                            <div><UserIcon className="w-4 h-4"/></div>
                                            <div className="ml-3">Account</div>
                                        </button>
                                    </Link>
                                )}
                            </Menu.Item>
                            <Menu.Item>
                                {({ active }) => (
                                    <Link href="/my">
                                        <button
                                            className={
                                                "flex text-gray-900 w-full px-4 py-2 text-left text-sm hover:bg-gray-100 dark:hover:bg-gray-700 dark:text-white items-center"
                                            }
                                        >
                                            <div><StarIcon className="w-4 h-4"/></div>
                                            <div className="ml-3">My images</div>
                                        </button>
                                    </Link>
                                )}
                            </Menu.Item>
                            <Menu.Item>
                                {({ active }) => (
                                    <button
                                        onClick={() => {
                                            supabase.auth.signOut();
                                            window.umami?.track("Avatar - Sign out", {});
                                            setTimeout(() => {
                                                window.location.href = "/";
                                            }, 1000);
                                        }}
                                        type="submit"
                                        className={
                                            "flex text-gray-900 w-full px-4 py-2 text-left text-sm hover:bg-gray-100 dark:hover:bg-gray-700 dark:text-white items-center"
                                        }
                                    >
                                        <div><ArrowRightOnRectangleIcon className="w-4 h-4"/></div>
                                        <div className="ml-3">Sign out</div>
                                    </button>
                                )}
                            </Menu.Item>
                        </form>
                    </div>
                </Menu.Items>
            </Menu>
        </>
    );
}
