import Link from "next/link";

import styles from "../styles/Home.module.css";
import { DarkModeToggle } from "./DarkModeToggle";

export default function Footer() {
    return (
        <footer className={styles.footer + " bg-white dark:bg-gray-950 flex flex-col leading-6 dark:text-gray-300 dark:hover:text-gray-100 border-t dark:border-gray-700"}>
            <div className="mb-8 text-left flex justify-start content-start mx-2">
                <div className="mr-3 sm:mr-12 flex flex-col items-start">
                    <div>
                        <Link href="/collection/natures-tales">
                            Nature&apos;s Tales Collection
                        </Link>
                    </div>
                    <div>
                        <Link href="/collection/liquid-elegance">Liquid Elegance Collection</Link>
                    </div>
                    <div>
                        <Link href="/collection/modern-musings">
                            Modern Musings Collection
                        </Link>
                    </div>
                    <div>
                        <Link href="/collection/pastel-portals">Pastel Portals Collection</Link>
                    </div>
                    <div>
                        <Link href="/collection">More collections</Link>
                    </div>
                </div>
                <div className="mr-2 hidden sm:mr-8 sm:flex flex-col items-start">
                    <div>
                        <Link href="/category/illustration">
                            Illustration Vector Images
                        </Link>
                    </div>
                    <div>
                        <Link href="/category/logo">Logo Vector Images</Link>
                    </div>
                    <div>
                        <Link href="/category/cartoon">
                            Cartoon Vector Images
                        </Link>
                    </div>
                    <div>
                        <Link href="/category/icon">Icon Vector Images</Link>
                    </div>
                    <div>
                        <Link href="/category">More categories</Link>
                    </div>
                </div>
                <div className="mr-2 hidden sm:mr-8 lg:flex flex-col items-start">
                    <div>
                        <Link href="/get-started/logo">
                            Logo Design
                        </Link>
                    </div>
                    <div>
                        <Link href="/get-started/illustration">
                            Illustration Design
                        </Link>
                    </div>
                    <div>
                        <Link href="/get-started/web-design">
                            Web Design
                        </Link>
                    </div>
                    <div>
                        <Link href="/get-started/t-shirt">
                            T-Shirt Design
                        </Link>
                    </div>
                    <div>
                        <Link href="/edit">
                            SVG Editor
                        </Link>
                    </div>
                    <div>
                        <Link href="/edit/remove-background">
                            SVG Background Remover
                        </Link>
                    </div>
                    <div>
                        <Link href="https://mylogo.ai">
                            MyLogo.ai
                        </Link>
                    </div>
                </div>
                <div className="mx-8  flex flex-col items-start font-medium">
                    <div>
                        <Link href="/browse">Browse</Link>
                    </div>
                    <div>
                        <Link href="/collection">Collections</Link>
                    </div>
                    <div>
                        <Link href="/category">Categories</Link>
                    </div>
                    <div>
                        <Link href="/create">Create</Link>
                    </div>
                    <div>
                        <Link href="/blog">Blog</Link>
                    </div>
                    <div>
                        <Link href="/status">Status monkey</Link>
                    </div>
                </div>
                <div className="ml-2 sm:ml-8 flex flex-col items-start font-medium">
                    <div>
                        <Link href="/pricing">Pricing</Link>
                    </div>
                    <div>
                        <Link href="/pricing#faq">FAQ</Link>
                    </div>
                    <div>
                        <Link href="https://docs.vectorart.ai">API</Link>
                    </div>
                    <div>
                        <Link href="/login">Sign in</Link>
                    </div>
                    <div>
                        <Link href="/login">Sign up</Link>
                    </div>
                    <div>
                        <Link href="/my">My images</Link>
                    </div>
                </div>
            </div>
            <div className="flex items-center justify-center mx-3 text-gray-500">
                <Link href="/terms">Terms</Link>{" "}
                <span className="text-gray-300">&nbsp;&nbsp;▪&nbsp;&nbsp;</span>{" "}
                <Link href="/privacy">Privacy</Link>
                <span className="text-gray-300">&nbsp;&nbsp;▪&nbsp;&nbsp;</span>{" "}
                <Link href="mailto:contact@vectorart.ai">Contact</Link>
                <span className="hidden sm:inline-block"><span className="text-gray-300">&nbsp;&nbsp;▪&nbsp;&nbsp;</span>© 2024
                VectorArt.ai by Nutmeg Studios LLC
                </span>
                <DarkModeToggle className="ml-16 right-0" />
            </div>
            <div className="sm:hidden mt-2">
                © 2024 VectorArt.ai by Nutmeg Studios LLC
            </div>
        </footer>
    );
}
